/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import _, { capitalize, Dictionary, flatten, isEmpty, union, uniqBy, values } from 'lodash';
import compareAsc from 'date-fns/compareAsc';

import { Category } from 'models/Category';
import { CommentProcessStatus } from 'models/Comment';
import { MerchantStatus, PaymentMethod, TransactionStatus } from 'models/Finance';
import { LuckyDrawType } from 'models/LuckyDraw';
import { RewardType } from 'models/Membership';
import { MessageType, TemplateMessagesUrlType, ReplyCommentType } from 'models/Message';
import Order from 'models/Order';
import Product from 'models/Product';
import { OptionDataType, ProductVariant, VariantAttributeType } from 'models/ProductVariant';
import TagName from 'models/TagName';
import User from 'models/User';
import UserSpace from 'models/UserSpace';
import Cart, { AssociatedAccount } from 'models/Cart';
import { geekblue, gold, gray, green, puple, red } from './colors';
import {
  CURRENT_LANGUAGE,
  CURRENT_SHOP_STORAGE_KEY,
  SLIP_VERIFICATION,
  isPH,
  isTH,
  SAVED_CART_IDS,
  SAVED_ORDER_IDS,
  CURRENT_CART_FILTER,
  CURRENT_ORDER_FILTER,
  APP_REGION,
  isVN,
  CURRENT_ANALYTICS_FILTER,
  CURRENT_LIVE_SESSION_FILTER,
  PROFILE_ID,
} from './constants';
import { ESData, generateShipmentFromEsData } from 'models/ESData';
import { AssociateAccount } from 'models/AssociateAccount';
import AdvancedFilter from 'models/AdvancedFilter';
import IdNamePair from 'models/IdNamePair';
import { ReportTemplate } from 'models/Report';
import Tag from 'models/Tag';
import { cartStatuses } from './cart';
import { Language } from 'context/language';
import { formatCurrency, getCurrencyUnitByString, formatNumberWithCommas, getCurrencyUnit } from './number';

import fbIcon from 'assets/Facebook.svg';
import igIcon from 'assets/instagram.svg';
import buyerIcon from 'assets/logo_icon.svg';
import { CustomerType } from 'models/Chat';
import { convertCustomerType } from './functions2';

export { formatCurrency, getCurrencyUnitByString, formatNumberWithCommas, getCurrencyUnit };

export const pascalCaseToCamelCase = (value: string): string => {
  return value.length === 0 ? value : value[0].toLowerCase() + value.substring(1);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const isBlank = (value: any): boolean => typeof value !== 'number' && _.isEmpty(value);

function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export const copyTextToClipboard = async (text: string): Promise<boolean> => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return true;
  }

  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (err) {
    console.error('Async: Could not copy text: ', err);
    return false;
  }
};

export const formatName = (user: User): string => `${user.firstName} ${user.lastName}`;

const mapRoutes: {
  [key: string]: string;
} = {
  'dashboard.dev.upmesh.io': 'https://subadmin.dev.upmesh.io',
  'dashboard.upmesh.io': 'https://subadmin.upmesh.io',
  'dashboard.stg.upmesh.io': 'https://subadmin.stg.upmesh.io',
  'dashboard.stg.upmesh.ph': 'https://subadmin.stg.upmesh.ph',
  'dashboard.stg.upmesh.my': 'https://subadmin.stg.upmesh.my',
  'dashboard.stg.upmesh.shop': 'https://subadmin.stg.upmesh.shop',
  'dashboard.employees.upmesh.io': 'https://subadmin.employees.upmesh.io',
  'dashboard-th.stg.upmesh.io': 'https://subadmin-th.stg.upmesh.io',
  'dashboard-urus.stg.upmesh.io': 'https://subadmin-urus.stg.upmesh.io',
  'dashboard.stg.upmesh.vn': 'https://subadmin.stg.upmesh.vn',
  'dashboard.upmesh.ph': 'https://subadmin.upmesh.ph',
  'dashboard.upmesh.my': 'https://subadmin.upmesh.my',
  'dashboard.upmesh.shop': 'https://subadmin.upmesh.shop',
  'dashboard-th.upmesh.io': 'https://subadmin-th.upmesh.io',
  'dashboard-pre.upmesh.io': 'https://subadmin-pre.upmesh.io',
  'dashboard-pre.upmesh.my': 'https://subadmin-pre.upmesh.my',
  'dashboard.upmesh.vn': 'https://subadmin.upmesh.vn',
  'dashboard.devone.upmesh.io': 'https://subadmin.devone.upmesh.io',
  'dashboard.devtwo.upmesh.io': 'https://subadmin.devtwo.upmesh.io',
  'dashboard.devthree.upmesh.io': 'https://subadmin.devthree.upmesh.io',
  'dashboard.devfour.upmesh.io': 'https://subadmin.devfour.upmesh.io',
  'dashboard.devfive.upmesh.io': 'https://subadmin.devfive.upmesh.io',
  'dashboard.devsix.upmesh.io': 'https://subadmin.devsix.upmesh.io',
  'dashboard.devseven.upmesh.io': 'https://subadmin.devseven.upmesh.io',
  'dashboard.deveight.upmesh.io': 'https://subadmin.deveight.upmesh.io',
  'dashboard.devnine.upmesh.io': 'https://subadmin.devnine.upmesh.io',
  'dashboard.devten.upmesh.io': 'https://subadmin.devten.upmesh.io',
  'dashboard.develeven.upmesh.io': 'https://subadmin.develeven.upmesh.io',
  localhost: 'localhost:3000',
};

export const mapShopRoutes: {
  [key: string]: string;
} = {
  'dashboard.dev.upmesh.io': 'https://buyer.dev.upmesh.io/shops',
  'subadmin.dev.upmesh.io': 'https://buyer.dev.upmesh.io/shops',
  'dashboard.devone.upmesh.io': 'https://buyer.devone.upmesh.io/shops',
  'subadmin.devone.upmesh.io': 'https://buyer.devone.upmesh.io/shops',
  'dashboard.devtwo.upmesh.io': 'https://buyer.devtwo.upmesh.io/shops',
  'subadmin.devtwo.upmesh.io': 'https://buyer.devtwo.upmesh.io/shops',
  'dashboard.devthree.upmesh.io': 'https://buyer.devthree.upmesh.io/shops',
  'subadmin.devthree.upmesh.io': 'https://buyer.devthree.upmesh.io/shops',
  'dashboard.devfour.upmesh.io': 'https://buyer.devfour.upmesh.io/shops',
  'subadmin.devfour.upmesh.io': 'https://buyer.devfour.upmesh.io/shops',
  'dashboard.devfive.upmesh.io': 'https://buyer.devfive.upmesh.io/shops',
  'subadmin.devfive.upmesh.io': 'https://buyer.devfive.upmesh.io/shops',
  'dashboard.devsix.upmesh.io': 'https://buyer.devsix.upmesh.io/shops',
  'subadmin.devsix.upmesh.io': 'https://buyer.devsix.upmesh.io/shops',
  'dashboard.devseven.upmesh.io': 'https://buyer.devseven.upmesh.io/shops',
  'subadmin.devseven.upmesh.io': 'https://buyer.devseven.upmesh.io/shops',
  'dashboard.deveight.upmesh.io': 'https://buyer.deveight.upmesh.io/shops',
  'subadmin.deveight.upmesh.io': 'https://buyer.deveight.upmesh.io/shops',
  'dashboard.devnine.upmesh.io': 'https://buyer.devnine.upmesh.io/shops',
  'subadmin.devnine.upmesh.io': 'https://buyer.devnine.upmesh.io/shops',
  'dashboard.devten.upmesh.io': 'https://buyer.devten.upmesh.io/shops',
  'subadmin.devten.upmesh.io': 'https://buyer.devten.upmesh.io/shops',
  'dashboard.develeven.upmesh.io': 'https://buyer.develeven.upmesh.io/shops',
  'subadmin.develeven.upmesh.io': 'https://buyer.develeven.upmesh.io/shops',
  'dashboard.stg.upmesh.io': 'https://buyer.stg.upmesh.io/shops',
  'dashboard.employees.upmesh.io': 'https://buyer.employees.upmesh.io/shops',
  'subadmin.stg.upmesh.io': 'https://buyer.stg.upmesh.io/shops',
  'subadmin.employees.upmesh.io': 'https://buyer.employees.upmesh.io/shops',
  'dashboard.upmesh.io': 'https://buyer.upmesh.io/shops',
  'subadmin.upmesh.io': 'https://buyer.upmesh.io/shops',
  'dashboard.dev.upmesh.ph': 'https://buyer.dev.upmesh.io/shops',
  'subadmin.dev.upmesh.ph': 'https://buyer.dev.upmesh.io/shops',
  'dashboard.stg.upmesh.ph': 'https://buyer.stg.upmesh.ph/shops',
  'subadmin.stg.upmesh.ph': 'https://buyer.stg.upmesh.ph/shops',
  'dashboard.upmesh.ph': 'https://buyer.upmesh.io/shops',
  'subadmin.upmesh.ph': 'https://buyer.upmesh.io/shops',
  'dashboard.stg.upmesh.my': 'https://buyer.stg.upmesh.my/shops',
  'subadmin.stg.upmesh.my': 'https://buyer.stg.upmesh.my/shops',
  'dashboard.upmesh.my': 'https://buyer.upmesh.my/shops',
  'subadmin.upmesh.my': 'https://buyer.upmesh.my/shops',
  'dashboard.stg.upmesh.shop': 'https://buyer.stg.upmesh.shop/shops',
  'subadmin.stg.upmesh.shop': 'https://buyer.stg.upmesh.shop/shops',
  'dashboard.upmesh.shop': 'https://buyer.upmesh.shop/shops',
  'subadmin.upmesh.shop': 'https://buyer.upmesh.shop/shops',
  'dashboard-th.stg.upmesh.io': 'https://buyer-th.stg.upmesh.io/shops',
  'subadmin-th.stg.upmesh.io': 'https://buyer-th.stg.upmesh.io/shops',
  'subadmin-urus.stg.upmesh.io': 'https://buyer-urus.stg.upmesh.io/shops',
  'dashboard-th.upmesh.io': 'https://buyer-th.upmesh.io/shops',
  'subadmin-th.upmesh.io': 'https://buyer-th.upmesh.io/shops',
  'dashboard.upmesh.vn': 'https://buyer.upmesh.vn/shops',
  'subadmin.upmesh.vn': 'https://buyer.upmesh.vn/shops',
  'dashboard-pre.upmesh.io': 'https://buyer-pre.upmesh.io/shops',
  'subadmin-pre.upmesh.io': 'https://buyer-pre.upmesh.io/shops',
  'subadmin-pre.upmesh.my': 'https://buyer-pre.upmesh.my/shops',
  localhost: 'https://buyer.dev.upmesh.io/shops',
};

export const getSubadminLink = () => mapRoutes[location.hostname] || location.origin;

export const generateInvitationLink = (code: string): string => {
  return `${getSubadminLink()}/invitation?code=${code}`;
};

export const searchField = (target?: string, search = ''): boolean => {
  return !!target?.toLowerCase()?.includes(search?.toLowerCase());
};

export const generateShopLink = (shopName: string): string => {
  return `${mapShopRoutes[location.hostname]}/${shopName}`;
};

export const generateCommentProcessStatus = (status: CommentProcessStatus): string => {
  switch (status) {
    case CommentProcessStatus.IgnorePageCreator:
    case CommentProcessStatus.IgnoreCommentCreateBeforeSyncTime:
    case CommentProcessStatus.IgnorePageComment:
    case CommentProcessStatus.IgnoreCommentWithNotFoundPage:
    case CommentProcessStatus.IgnoreCommentWithNotFoundPost:
    case CommentProcessStatus.IgnoreManualComment:
      return 'ignored_comment';
    case CommentProcessStatus.CartCreated:
      return 'comment_generated_a_cart';
    case CommentProcessStatus.CartManualCreated:
      return 'manual_cart_generated';
    case CommentProcessStatus.ProductOutOfStock:
      return 'product_was_out_of_stock';
    case CommentProcessStatus.CommentParseFailed:
    case CommentProcessStatus.WelcomeMessageSent:
      return 'comment_parse_failed';
    case CommentProcessStatus.CommentParseQuantityFailed:
      return 'comment_parse_quantity_failed';
    case CommentProcessStatus.ProductNotAssociatedToPost:
      return 'product_not_associated_to_post';
    case CommentProcessStatus.ProductNotFound:
      return 'product_not_found';
    case CommentProcessStatus.ProductReachedMaxQuantity:
      return 'product_reached_max_quantity';
    case CommentProcessStatus.ProductInactive:
      return 'product_is_inactive';
    default:
      return '';
  }
};

export const generateColorByProcessStatus = (status: CommentProcessStatus): string => {
  switch (status) {
    case CommentProcessStatus.IgnorePageCreator:
    case CommentProcessStatus.IgnoreCommentCreateBeforeSyncTime:
    case CommentProcessStatus.IgnorePageComment:
    case CommentProcessStatus.IgnoreCommentWithNotFoundPage:
    case CommentProcessStatus.IgnoreCommentWithNotFoundPost:
    case CommentProcessStatus.IgnoreManualComment:
      return puple[5];
    case CommentProcessStatus.CartCreated:
      return green[5];
    case CommentProcessStatus.CartManualCreated:
      return geekblue[7];
    case CommentProcessStatus.ProductOutOfStock:
    case CommentProcessStatus.ProductNotFound:
    case CommentProcessStatus.ProductReachedMaxQuantity:
    case CommentProcessStatus.ProductNotAssociatedToPost:
    case CommentProcessStatus.ProductInactive:
      return gold[6];
    case CommentProcessStatus.CommentParseFailed:
    case CommentProcessStatus.WelcomeMessageSent:
      return gray[5];
    case CommentProcessStatus.CommentParseQuantityFailed:
      return red[5];
    default:
      return '';
  }
};

function isInt(str: string) {
  return !isNaN(+str) && Number.isInteger(parseFloat(str));
}

export const checkTimeFormat = (timeString: string): string => {
  if (!timeString) return 'valid';
  const values = timeString.split(':');
  const [hours, minutes, seconds] = [...values];
  if (values.length !== 3 || !hours || !minutes || !seconds || !isInt(hours) || !isInt(minutes) || !isInt(seconds)) {
    return 'time_format_error';
  }
  if (+hours > 720 || +hours < 0 || (+hours === 720 && (+minutes !== 0 || +seconds !== 0))) {
    return 'hours_invalid_error';
  }
  if (+minutes > 59 || +minutes < 0) {
    return 'minutes_invalid_error';
  }
  if (+seconds > 59 || +seconds < 0) {
    return 'seconds_invalid_error';
  }
  return 'valid';
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const secondsToHms = (totalSeconds: any): string => {
  if (!totalSeconds) return '00:00:00';
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);
  return `${hours / 10 >= 1 ? hours : `0${hours}`}:${minutes / 10 >= 1 ? minutes : `0${minutes}`}:${
    seconds / 10 >= 1 ? seconds : `0${seconds}`
  }`;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const hmsToSeconds = (timeString: any): number => {
  const [hours, minutes, seconds] = timeString.split(':');
  const totalSeconds = +hours * 3600 + +minutes * 60 + +seconds;
  return totalSeconds;
};

export const isSubadmin = (): boolean => {
  return [
    'subadmin.dev.upmesh.io',
    'subadmin.devone.upmesh.io',
    'subadmin.devtwo.upmesh.io',
    'subadmin.devthree.upmesh.io',
    'subadmin.devfour.upmesh.io',
    'subadmin.devfive.upmesh.io',
    'subadmin.devsix.upmesh.io',
    'subadmin.devseven.upmesh.io',
    'subadmin.deveight.upmesh.io',
    'subadmin.devnine.upmesh.io',
    'subadmin.devten.upmesh.io',
    'subadmin.develeven.upmesh.io',
    'subadmin.stg.upmesh.io',
    'subadmin.employees.upmesh.io',
    'subadmin.stg.upmesh.my',
    'subadmin.stg.upmesh.shop',
    'subadmin-th.stg.upmesh.io',
    'subadmin.stg.upmesh.vn',
    'subadmin.upmesh.io',
    'subadmin.upmesh.ph',
    'subadmin.upmesh.my',
    'subadmin.upmesh.shop',
    'subadmin-th.upmesh.io',
    'subadmin.upmesh.vn',
    'subadmin-pre.upmesh.io',
    'subadmin-pre.upmesh.my',
    // 'localhost',
  ].includes(window.location.hostname);
};

export const dataUrlToFile = async (dataUrl: string, fileName: string): Promise<File> => {
  const headers = new Headers();
  headers.append('Cache-Control', 'no-cache');
  const res: Response = await fetch(dataUrl, { headers: headers });
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: 'image/png' });
};

export const currentUser = (): string => {
  const currentUserSpace = localStorage.getItem(CURRENT_SHOP_STORAGE_KEY);
  if (currentUserSpace) {
    const parsedCurrentUserSpace: UserSpace = JSON.parse(currentUserSpace);
    return parsedCurrentUserSpace.ownerKey;
  }
  return '';
};

export const formatMembershipColor = (type: RewardType): string => {
  switch (type) {
    case RewardType.CreditReward:
      return '#DCF2DD';
    case RewardType.DiscountPercentage:
      return '#FAE5D8';
    default:
      return '#ebcafb';
  }
};

export const analytics = (window as any).analytics;

export const pages: { [key: string]: string } = {
  '/profile': 'Profile Settings',
  '/payment': 'Payment Settings',
  '/delivery': 'Delivery Settings',
  '/create-custom-delivery': 'Delivery Settings',
  '/edit-custom-delivery': 'Delivery Settings',
  '/products': 'Product Management',
  '/product': 'Product Management',
  '/dashboard': 'Post Management',
  '/product-search': 'Product History',
  '/carts': 'Pending Orders',
  '/carts?selectedCart': 'Pending Orders-cart details',
  '/orders': 'Paid Orders',
  '/promotion': 'Promotions',
  '/create-promotion': 'Promotions',
  '/edit-promotion': 'Promotions',
  '/view-promotion': 'Promotions',
  '/bundle-deals': 'Bundle Deal',
  '/create-bundle': 'Bundle Deal',
  '/edit-bundle': 'Bundle Deal',
  '/view-bundle': 'Bundle Deal',
  '/lucky-draw': 'Lucky Draw',
  '/analytics': 'Analytics',
  '/permissions': 'Users Permission',
  '/catalogue': 'Catalogue Management',
  '/product-groups': 'Product Groups Management',
  '/post-templates': 'Post Templates Management',
  '/product-log': 'Product Log',
  '/memberships': 'Memberships',
  '/credit': 'Credits Management',
  '/login': 'Login',
  '/settings': 'Setting',
  '/messages': 'Message Management',
  '/price-drop': 'Price Drop',
  '/financial-account': 'Financial Account',
  '/card-transactions': 'Card Transactions',
  '/logistic': 'Logistic Page',
  '/fulfill-orders': 'Order Fullfillment Page',
  '/shippingChannel': 'Shipping Channel Page',
  '/shippingSettings': 'Shipping Settings Page',
  '/reports': 'Generate Report Page',
  '/live-sessions': 'Live Selling Management',
  '/items-sold': 'Items Sold',
};

export const listBlockedMerchant = [
  'auth0|6114b9da2b1c5d006944cd70',
  'auth0|6117543f8b72f7006ae6b340',
  'auth0|6127a2da7836e80069e2bf71',
  'auth0|61275324aaab2f006adfe144',
  'auth0|6117543f8b72f7006ae6b340',
  'auth0|6127a2da7836e80069e2bf71',
  'auth0|61279b0fb5b076006af3da84',
  'auth0|6127a2da7836e80069e2bf71',
  'auth0|6114b9da2b1c5d006944cd70',
  'auth0|611499a5b151ce00685ea795',
  'auth0|6117543f8b72f7006ae6b340',
  'auth0|6127a2da7836e80069e2bf71',
  'auth0|61275324aaab2f006adfe144',
  'auth0|6127a2da7836e80069e2bf71',
  'auth0|611499a5b151ce00685ea795',
  'auth0|6117543f8b72f7006ae6b340',
  'auth0|6114b9da2b1c5d006944cd70',
  'auth0|61279b0fb5b076006af3da84',
  'auth0|6117543f8b72f7006ae6b340',
  'auth0|61279b0fb5b076006af3da84',
  'auth0|61275324aaab2f006adfe144',
  'auth0|6117543f8b72f7006ae6b340',
  'auth0|61275324aaab2f006adfe144',
  'auth0|611499a5b151ce00685ea795',
  'auth0|6117543f8b72f7006ae6b340',
  'auth0|6127a2da7836e80069e2bf71',
  'auth0|61279b0fb5b076006af3da84',
  'auth0|6114b9da2b1c5d006944cd70',
  'auth0|6117543f8b72f7006ae6b340',
  'auth0|6127a2da7836e80069e2bf71',
  'auth0|61279b0fb5b076006af3da84',
  'auth0|611499a5b151ce00685ea795',
  'auth0|61279b0fb5b076006af3da84',
  'auth0|61275324aaab2f006adfe144',
  'auth0|6127a2da7836e80069e2bf71',
  'auth0|61275324aaab2f006adfe144',
  'auth0|6114b9da2b1c5d006944cd70',
  'auth0|611499a5b151ce00685ea795',
  'auth0|611499a5b151ce00685ea795',
  'auth0|611499a5b151ce00685ea795',
  'auth0|6114b9da2b1c5d006944cd70',
  'auth0|6127a2da7836e80069e2bf71',
  'auth0|6114b9da2b1c5d006944cd70',
  'auth0|6114b9da2b1c5d006944cd70',
  'auth0|61279b0fb5b076006af3da84',
  'auth0|611499a5b151ce00685ea795',
  'auth0|611499a5b151ce00685ea795',
  'auth0|6117543f8b72f7006ae6b340',
  'auth0|611499a5b151ce00685ea795',
  'auth0|6117543f8b72f7006ae6b340',
  'auth0|61279b0fb5b076006af3da84',
  'auth0|6127a2da7836e80069e2bf71',
  'auth0|61275324aaab2f006adfe144',
  'auth0|6117543f8b72f7006ae6b340',
  'auth0|61275324aaab2f006adfe144',
  'auth0|6114b9da2b1c5d006944cd70',
  'auth0|611499a5b151ce00685ea795',
  'auth0|61275324aaab2f006adfe144',
  'auth0|6117543f8b72f7006ae6b340',
  'auth0|61279b0fb5b076006af3da84',
  'auth0|61275324aaab2f006adfe144',
  'auth0|61279b0fb5b076006af3da84',
  'auth0|6117543f8b72f7006ae6b340',
  'auth0|61279b0fb5b076006af3da84',
  'auth0|6114b9da2b1c5d006944cd70',
  'auth0|6117543f8b72f7006ae6b340',
  'auth0|6127a2da7836e80069e2bf71',
  'auth0|61275324aaab2f006adfe144',
  'auth0|611499a5b151ce00685ea795',
  'auth0|611499a5b151ce00685ea795',
  'auth0|611499a5b151ce00685ea795',
  'auth0|611499a5b151ce00685ea795',
  'auth0|611499a5b151ce00685ea795',
  'auth0|61275324aaab2f006adfe144',
  'auth0|6127a2da7836e80069e2bf71',
  'auth0|61275324aaab2f006adfe144',
  'auth0|61275324aaab2f006adfe144',
  'auth0|61279b0fb5b076006af3da84',
  'auth0|6127a2da7836e80069e2bf71',
  'auth0|61279b0fb5b076006af3da84',
  'auth0|6114b9da2b1c5d006944cd70',
  'auth0|6117543f8b72f7006ae6b340',
  'auth0|6114b9da2b1c5d006944cd70',
  'auth0|6114b9da2b1c5d006944cd70',
  'auth0|611499a5b151ce00685ea795',
  'auth0|6127a2da7836e80069e2bf71',
  'auth0|6114b9da2b1c5d006944cd70',
  'auth0|6117543f8b72f7006ae6b340',
  'auth0|61279b0fb5b076006af3da84',
  'auth0|6127a2da7836e80069e2bf71',
  'auth0|6114b9da2b1c5d006944cd70',
  'auth0|61279b0fb5b076006af3da84',
  'auth0|61279b0fb5b076006af3da84',
  'auth0|6117543f8b72f7006ae6b340',
  'auth0|61279b0fb5b076006af3da84',
  'auth0|61275324aaab2f006adfe144',
  'auth0|6127a2da7836e80069e2bf71',
  'auth0|6114b9da2b1c5d006944cd70',
  'auth0|611499a5b151ce00685ea795',
  'auth0|6127a2da7836e80069e2bf71',
  'auth0|61279b0fb5b076006af3da84',
  'auth0|61275324aaab2f006adfe144',
  'auth0|61275324aaab2f006adfe144',
  'auth0|61275324aaab2f006adfe144',
  'auth0|6127a2da7836e80069e2bf71',
  'auth0|6114b9da2b1c5d006944cd70',
  'auth0|6117543f8b72f7006ae6b340',
  'auth0|6114b9da2b1c5d006944cd70',
  'facebook|10159893205168804',
  'facebook|344000380469280',
  'facebook|10159507088458147',
  'facebook|539138090651421',
  'facebook|417228513297021',
  'facebook|4905799222766798',
  'facebook|216244657116787',
  'facebook|2524672087676204',
  'facebook|4352041431484984',
  'facebook|1857285271119439',
  'facebook|10159936807154063',
  'facebook|10158078409518786',
  'facebook|555977079101511',
];

export const listBlockDomains = ['dashboard.upmesh.io', 'subadmin.upmesh.io'];

export const generateTransactionStatus = (status?: TransactionStatus): string => {
  switch (status) {
    case TransactionStatus.Created:
      return 'created';
    case TransactionStatus.Succeeded:
      return 'succeeded';
    case TransactionStatus.Settled:
      return 'settled';
    case TransactionStatus.Failed:
      return 'failed';
    case TransactionStatus.Cancelled:
      return 'cancelled';
    case TransactionStatus.Refunded:
      return 'refunded';
    case TransactionStatus.Withdrawable:
      return 'withdrawable';
    default:
      return '';
  }
};

export const generateMerchantStatus = (status?: MerchantStatus): string => {
  switch (status) {
    case MerchantStatus.Pending:
      return 'pending';
    case MerchantStatus.Reject:
      return 'rejected';
    case MerchantStatus.Verified:
      return 'verified';
    case MerchantStatus.Incomplete:
      return 'incomplete';
    default:
      return '';
  }
};

// @todo: update it when we have new payment method type
export const generatePaymentChannel = (method?: PaymentMethod): string => {
  switch (method) {
    case PaymentMethod.Card:
    case PaymentMethod.CardPaymongo:
    case PaymentMethod.CardStripe:
    case PaymentMethod.Card2c2p:
      return 'Card';
    case PaymentMethod.Grab:
    case PaymentMethod.GrabStripe:
    case PaymentMethod.GrabPay2c2p:
      return 'Grab';
    case PaymentMethod.GrabPaymongo:
      return isTH ? 'Grab Paymongo' : 'Grab';
    case PaymentMethod.GCashPaymongo:
      return 'GCash';
    case PaymentMethod.Boost2c2p:
      return 'Boost';
    case PaymentMethod.Paynow:
    case PaymentMethod.PaynowStripe:
      return 'Paynow';
    case PaymentMethod.Shopeepay2c2p:
      return 'ShopeePay';
    case PaymentMethod.AliPay2c2p:
      return 'AliPay';
    case PaymentMethod.WechatPay2c2p:
      return 'WechatPay';
    case PaymentMethod.PromptPay2c2p:
      return 'PromptPay';
    case PaymentMethod.FPX2c2p:
      return 'FPX';
    case PaymentMethod.TouchNGo2c2p:
      return 'Touch N Go';
    case PaymentMethod.Offline:
      return 'manual_verification';
    default:
      return '';
  }
};

export const getAcceptLanguage = (): string => {
  const env = APP_REGION;
  let defaultLanguage: Language;
  switch (env) {
    case 'ph':
      defaultLanguage = 'ph';
      break;
    case 'my':
      defaultLanguage = 'en';
      break;
    case 'th':
      defaultLanguage = 'th';
      break;
    case 'vn':
      defaultLanguage = 'vi';
      break;
    default:
      defaultLanguage = 'en';
      break;
  }
  const currentLanguage = localStorage.getItem(CURRENT_LANGUAGE) || defaultLanguage;
  switch (currentLanguage) {
    case 'zh':
      return 'zh-cn';
    case 'th':
      return 'tha';
    case 'ms':
      return 'mys';
    case 'vi':
      return 'vn';
    default:
      return 'en-us';
  }
};

export const generatePinMessageContent = (data: (Product | ProductVariant)[]): string => {
  if (isPH)
    return data
      .map(
        (o) =>
          `${o.productName} ${formatProductVariantName(o.productVariantValues, 'short')}, ${formatCurrency(
            o.price
          )}\n MINE ${o.productCode}`
      )
      .join('\n');
  return data
    .map(
      (o) =>
        `${o.productName} ${formatProductVariantName(o.productVariantValues, 'short')}, ${formatCurrency(o.price)}\n ${
          o.productCode
        }+1`
    )
    .join('\n');
};

export const formatLongNumber = (num: number | undefined): string | undefined => {
  if (typeof num !== 'number' && !num) return undefined;

  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /^-?\d+(?:\.\d{0,1})?/;
  const item = lookup
    .slice()
    .reverse()
    .find((item) => num >= item.value);
  if (!item) return num.toString();
  const value = (num / item.value).toString();
  if (item.symbol === '' && value.length <= 5) {
    return value;
  }
  return value.toString().match(rx)?.[0] + item.symbol;
};

export const validatePrice = (num: string): boolean => {
  const regex = /^[0-9]*(\.[0-9]{0,2})?$/;
  return regex.test(num);
};

export const formatPrice = (price: string): string => {
  return price.indexOf('.') >= 0 ? price.substr(0, price.indexOf('.')) + price.substr(price.indexOf('.'), 3) : price;
};
export const formatLimitedInputContent = (content: string, maxRows: number, maxCharactersPerLine: number): string => {
  const lines = content.split('\n');
  for (let i = 0; i < lines.length; i++) {
    if (lines[i].length <= maxCharactersPerLine) continue;
    let j = 0;
    let space = maxCharactersPerLine;
    while (j++ <= maxCharactersPerLine) {
      if (lines[i].charAt(j) === ' ') space = j;
    }
    lines[i + 1] = lines[i].substring(space + 1) + (lines[i + 1] || '');
    lines[i] = lines[i].substring(0, space);
  }
  return lines.slice(0, maxRows).join('\n');
};

export const formatShipmentFromOrderData = (order: Order): Order[] => {
  const isNotHaveGeneralProduct = order && !order?.deliveryDetails?.deliveryOptionId;
  const generalGroup = isNotHaveGeneralProduct
    ? {}
    : {
        ...order,
        shipmentRowKey: `${order?.id}-${order?.deliveryDetails?.deliveryOptionId}`,
        groupCartId: order.cartId,
        shippingGroupDetailItems: [],
        paymentDetails: {},
        orderTags: order?.orderTags?.filter((o) => o.shippingGroupId === null),
      };
  const shipmentGroups =
    order?.shippingGroupDetailItems?.map((group) => ({
      ...order,
      orderReferenceCode: group.orderGroupReferenceCode,
      shipmentRowKey: group.shippingGroupId || '',
      groupCartId: order.cartId,
      deliveryDetails: {
        ...order?.deliveryDetails,
        deliveryOptionId: null,
        deliveryOption: null,
        deliverySurcharge: 0,
      },
      paymentDetails: {},
      shippingGroupDetailItems: [group],
      reason: group.reason,
      orderTags: order?.orderTags?.filter((o) => o.shippingGroupId === group.shippingGroupId),
    })) || [];

  return isNotHaveGeneralProduct ? [...shipmentGroups] : [generalGroup, ...shipmentGroups];
};

export const getCartSortKey = (fieldKey: string): string => {
  switch (fieldKey) {
    case 'cartStatus':
      return 'cart_status';
    case 'id':
      return 'cart_id';
    case 'pageId':
      return 'page_names';
    case 'created':
      return 'cart_created_at';
    case 'orderCreated':
      return 'order_created_at';
    case 'lastUpdated':
      return 'cart_updated_at';
    case 'expiredAt':
      return 'cart_expired_at';
    default:
      return fieldKey;
  }
};

export const getOrderSortKey = (fieldKey: string): string => {
  switch (fieldKey) {
    case 'customer':
      return 'customer_name';
    case 'recipient':
      return 'recipient_name';
    case 'total':
      return 'order_total';
    case 'cartCreated':
      return 'cart_created_at';
    case 'created':
      return 'order_created_at';
    case 'lastUpdateDeliveryStatus':
      return 'last_updated_delivery_status';
    default:
      return fieldKey;
  }
};

export const getProductSortKey = (fieldKey: string | number) => {
  switch (fieldKey) {
    case 'productName':
      return 'product_name';
    case 'productCode':
      return 'product_code';
    case 'created':
      return 'product_created_at';
    case 'productSold':
      return 'product_sold';
    case 'productSoldTracking':
      return 'product_sold_tracking';
    case 'productReserved':
      return 'product_reserved';
    case 'productStock':
      return 'product_stock';
    case 'maximumQuantityPerPost':
      return 'max_quantity_per_post';
    case 'costPrice':
      return 'cost_price';
    case 'price':
      return 'price';
    case 'pinnedUpdatedAt':
      return 'pinned_updated_at';
    case 'productReservedAndSold':
      return 'product_reserved_and_sold';
    case 'productTotalStock':
      return 'product_total_stock';
    default:
      return fieldKey;
  }
};

export const getBundleDealSortKey = (fieldKey: string | number) => {
  switch (fieldKey) {
    case 'createdAt':
      return 'created';
    default:
      return fieldKey;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const filterMergeCustomizer = (objValue: any, srcValue: any) => {
  if (_.isArray(objValue)) {
    return union(objValue, srcValue);
  }
};
//feature flag at FE side
const whiteListMerchantForSlipVerification = [
  'facebook|1915298691953643',
  'facebook|465282198332720',
  'facebook|2950519628610894',
  'facebook|109911691663148',
  'facebook|1418865821823860',
  'facebook|107596028561564',
  'facebook|10221535929094585',
  'facebook|10220418316783425',
  'facebook|10221622084929011',
  'facebook|10158343328961829',
  'facebook|6065605533483016',
  'facebook|4926838027395535',
  'facebook|7115658088475971',
  'facebook|502822254658507',
  'facebook|4971280776244389',
  'facebook|1532276960502577',
];

export const hasFeatureFlag = (feature: string) => {
  const user = currentUser();
  switch (feature) {
    case SLIP_VERIFICATION:
      return whiteListMerchantForSlipVerification.includes(user);
    default:
      return true;
  }
};

export const getDeviceType = () => {
  const type = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(type)) {
    return 'tablet';
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(type)
  ) {
    return 'mobile';
  }
  return 'desktop';
};

export const getMsgWithoutTagLength = (message: string, tags: string[]) => {
  let messageWithoutTag = message;
  tags.forEach((tag) => {
    messageWithoutTag = messageWithoutTag.replaceAll(tag, '');
  });
  return messageWithoutTag.length;
};

export const generateTitleLuckyDraw = (type?: LuckyDrawType) => {
  switch (type) {
    case LuckyDrawType.Purchaser:
      return 'purchaser_draw';
    case LuckyDrawType.Liker:
      return 'liker_draw';
    case LuckyDrawType.Share:
      return 'sharer_lns_draw';
    default:
      return '';
  }
};

export const generateTitleLuckyDrawWinner = (type?: LuckyDrawType) => {
  switch (type) {
    case LuckyDrawType.Purchaser:
      return 'purchaser_draw_winner';
    case LuckyDrawType.Liker:
      return 'liker_draw_winner';
    case LuckyDrawType.Share:
      return 'sharer_lns_draw_winner';
    default:
      return '';
  }
};

export const templateMessagesUrl = (type: TemplateMessagesUrlType) =>
  `/facebook-service/api/template-messages?type=${type}`;

export const checkIsSingleShipment = (order?: Order) => {
  return order?.shipments?.length === 1;
};

// SAVED_CART_IDS
export const setSavedCartIds = (ids: string[] | undefined) => {
  return localStorage.setItem(SAVED_CART_IDS, JSON.stringify(ids));
};

export const getSavedCartIds = () => {
  try {
    return JSON.parse(localStorage.getItem(SAVED_CART_IDS) || '[]');
  } catch (e) {
    //
  }
};

export const removeSavedCartIds = () => {
  return localStorage.removeItem(SAVED_CART_IDS);
};

// SAVED_ORDER_IDS
export const setSavedOrderIds = (ids: string[] | undefined) => {
  return localStorage.setItem(SAVED_ORDER_IDS, JSON.stringify(ids));
};

export const getSavedOrderIds = () => {
  try {
    return JSON.parse(localStorage.getItem(SAVED_ORDER_IDS) || '[]');
  } catch (e) {
    //
  }
};

export const removeSavedOrderIds = () => {
  return localStorage.removeItem(SAVED_ORDER_IDS);
};

// CURRENT_CART_FILTER
export const setCurrentCartFilter = (filterString: string) => {
  return localStorage.setItem(CURRENT_CART_FILTER, filterString);
};

export const getCurrentCartFilter = () => {
  return localStorage.getItem(CURRENT_CART_FILTER) || '{}';
};

export const removeCurrentCartFilter = () => {
  return localStorage.removeItem(CURRENT_CART_FILTER);
};

export const getProfileId = () => {
  return localStorage.getItem(PROFILE_ID);
};

// CURRENT_ORDER_FILTER
export const setCurrentOrderFilter = (filterString: string) => {
  return localStorage.setItem(CURRENT_ORDER_FILTER, filterString);
};

export const getCurrentOrderFilter = () => {
  return localStorage.getItem(CURRENT_ORDER_FILTER) || '{}';
};

export const removeCurrentOrderFilter = () => {
  return localStorage.removeItem(CURRENT_ORDER_FILTER);
};

// CURRENT_ANALYTICS_FILTER
export const removeCurrentAnalyticsFilter = () => {
  return localStorage.removeItem(CURRENT_ANALYTICS_FILTER);
};

export const getCurrentAnalyticsFilter = () => {
  return localStorage.getItem(CURRENT_ANALYTICS_FILTER);
};

export const setCurrentAnalyticsFilter = (filterString: string) => {
  return localStorage.setItem(CURRENT_ANALYTICS_FILTER, filterString);
};

export const setCurrentLiveSessionFilter = (filterString: string) => {
  return localStorage.setItem(CURRENT_LIVE_SESSION_FILTER, filterString);
};

export const getCurrentLiveSessionFilter = () => {
  return localStorage.getItem(CURRENT_LIVE_SESSION_FILTER);
};

export const removeCurrentLiveSessionFilter = () => {
  return localStorage.removeItem(CURRENT_LIVE_SESSION_FILTER);
};

export const getCroppedImg = (image: HTMLImageElement, crop: ReactCrop.Crop, enableCrop: boolean) => {
  const mimeString = image.src.split(',')[0].split(':')[1].split(';')[0];
  const canvas = document.createElement('canvas');
  const cropWidth = (crop.width as number) === 0 ? image.width : (crop.width as number);
  const cropHeigth = (crop.height as number) === 0 ? image.height : (crop.height as number);
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const targetX = (crop.x as number) * scaleX;
  const targetY = (crop.y as number) * scaleY;
  const targetWidth = cropWidth * scaleX;
  const targetHeight = cropHeigth * scaleY;

  canvas.width = enableCrop ? targetWidth : image.width;
  canvas.height = enableCrop ? targetHeight : image.height;

  const ctx = canvas.getContext('2d');

  enableCrop
    ? ctx?.drawImage(image, targetX, targetY, targetWidth, targetHeight, 0, 0, targetWidth, targetHeight)
    : ctx?.drawImage(image, 0, 0, image.width, image.height);

  return new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        resolve(blob);
      },
      mimeString,
      image.width / image.naturalWidth
    );
  });
};

export const getImage = (url: string) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = url;
    image.onload = () => {
      resolve(image);
    };
    image.onerror = (error) => reject(error);
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const checkExtension = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    let type: string;
    reader.readAsArrayBuffer(file);
    reader.onload = (e: any) => {
      const arr = new Uint8Array(e.target.result).subarray(0, 4);
      let header = '';
      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      switch (header) {
        case '89504e47':
          type = 'image/png';
          break;
        case 'ffd8ffdb':
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffe3':
        case 'ffd8ffe8':
          type = 'image/jpeg';
          break;
        default:
          type = 'unknown';
          break;
      }
      resolve(type);
    };
    reader.onerror = (error) => reject(error);
  });
};

export const generateTagColor = (tagType: TagName) => {
  switch (tagType) {
    case TagName.MultiShipment:
      return {
        backgroundColor: '#F9F0FF',
        borderColor: '#EFDBFF',
        color: '#722ED1',
      };
    case TagName.ManuallyCreated:
      return {
        backgroundColor: '#FFF0F6',
        borderColor: '#FFD6E7',
        color: '#EB2F96',
      };

    case TagName.SingleShipment:
      return {
        backgroundColor: '#E6FFFB',
        borderColor: '#B5F5EC',
        color: '#08979C',
      };
    case TagName.FromComment:
      return {
        backgroundColor: '#FFFBE6',
        borderColor: '#FFF1B8',
        color: '#D48806',
      };
    case TagName.FromCatalogue:
      return {
        backgroundColor: '#FFF2E8',
        borderColor: '#FFD8BF',
        color: '#FA541C',
      };
    case TagName.ActionRequired:
      return {
        backgroundColor: '#FFC53D',
        color: '#000',
        border: 'none',
      };
    case TagName.NewCart:
      return {
        backgroundColor: '#F6FFED',
        color: '#237804',
        borderColor: '#D9F7BE',
      };

    case TagName.FromMessage:
      return {
        backgroundColor: '#FFF7E6',
        color: '#D46B08',
        borderColor: '#FFE7BA',
      };

    case TagName.MergedShipment:
      return {
        backgroundColor: '#F0F5FF',
        borderColor: '#D6E4FF',
        color: '#2F54EB',
      };

    case TagName.PartiallyPaid:
      return {
        backgroundColor: '#FA8C16',
        borderColor: '#FA8C16',
        color: '#000000D9',
      };

    case TagName.ToRefund:
      return {
        backgroundColor: '#FFC53D',
        borderColor: '#FFC53D',
        color: '#000000D9',
      };

    default:
      return {};
  }
};

export const formatVariantWithProductInfo = (product: Product, variants?: ProductVariant[]) => {
  return (
    variants?.map((variant) => ({
      ...variant,
      masterProductId: product?.id || '',
      masterProductName: product?.productName || '',
      masterProductCode: product?.productCode || '',
      masterProductImages: product?.productImages || [],
    })) || []
  );
};

export const formatProductVariantName = (variantValues?: VariantAttributeType[], type = 'long', hasBracket = true) => {
  let content = '';
  if (isBlank(variantValues)) return content;
  if (type === 'long') {
    variantValues?.map((value, index) => {
      const text = `${capitalize(value.attributeName)}: ${capitalize(value.optionName)}`;
      if (index !== variantValues.length - 1) {
        return (content += `${text} | `);
      }
      return (content += text);
    });
  } else {
    if (hasBracket) {
      content = `(${variantValues?.map((variant) => variant.optionName)})`;
    } else {
      content = `${variantValues?.map((variant) => variant.optionName)}`;
    }
  }
  return content;
};

export const isEmptyObject = (obj: any) => values(obj).every((o) => !o);
export const numberToBase26 = (val: any, tail = ''): any => {
  if (val <= 26) {
    return `${String.fromCharCode(val + 64)}${tail}`;
  }

  const remainder = val % 26 || 26;
  const division = Math.trunc(val / 26) - (remainder === 26 ? 1 : 0);

  return numberToBase26(division, `${String.fromCharCode(remainder + 64)}${tail}`);
};

export const convertStatusesToString = (statuses: IdNamePair[] | undefined, type: 'carts' | 'orders'): IdNamePair[] => {
  if (!statuses || isEmpty(statuses)) return [];
  const status = cartStatuses.map((item) => ({ id: item + '', name: item + '' }));
  return statuses[0].id === 'all'
    ? type === 'carts'
      ? status
      : []
    : statuses.map((status) => ({ id: status.id, name: status.name }));
};

export const generateLanguageByCode = (lang: string) => {
  switch (lang) {
    case 'en':
      return 'English';
    case 'ph':
      return 'Tagalog';
    case 'zh':
      return '中文';
    case 'th':
      return 'ไทย';
    case 'ms':
      return 'Bahasa Melayu';
    case 'vi':
      return 'Tiếng Việt';
  }
};

export const generatePlatform = (type?: string | CustomerType) => {
  switch (type) {
    case CustomerType.FACEBOOK:
    case 'facebook':
      return fbIcon;
    case CustomerType.INSTAGRAM:
    case 'instagram':
      return igIcon;
    case CustomerType.BuyerApp:
    case 'buyerApp':
    case 'buyerapp':
      return buyerIcon;
    default:
      return '';
  }
};

export const generateSearchParamInOrder = (searchType?: string, search?: string) => {
  switch (searchType) {
    case 'all':
      return { _keywords: search };
    case 'facebook':
    case 'instagram':
      return { customer_name: search, customer_type: searchType };
    case 'cartIds':
      return search ? { cart_ids: [search] } : { cart_ids: [] };
    case 'buyerApp':
      return { buyer_name: search };
    case 'recipient':
      return { recipient_name: search };
    case 'phoneNumber':
      return { recipient_phone_number: search };
    case 'postalCode':
      return { postal_code: search };
    default:
      return {};
  }
};

export const generateSearchTypeInOrder = (filter: AdvancedFilter) => {
  if (['facebook', 'instagram'].includes(filter?.customer_type || '') && filter.customer_name)
    return { type: filter.customer_type, keyword: filter.customer_name };
  if (!isEmpty(filter.cart_ids)) {
    return { type: 'cartIds', keyword: filter.cart_ids?.[0] };
  }
  if (filter.buyer_name) {
    return { type: 'buyerApp', keyword: filter.buyer_name };
  }
  if (filter.recipient_name) {
    return { type: 'recipient', keyword: filter.recipient_name };
  }
  if (filter.recipient_phone_number) {
    return { type: 'phoneNumber', keyword: filter.recipient_phone_number };
  }
  if (filter.postal_code) {
    return { type: 'postalCode', keyword: filter.postal_code };
  }
  return { type: 'all', keyword: filter._keywords };
};

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const connectCategoryLevels = ({
  categories,
  currentLevel,
  childCategoriesList,
}: {
  categories?: Category[];
  currentLevel: number;
  childCategoriesList: { [key: string]: Category[] };
}) => {
  const formattedParentLevel: Category[] =
    categories
      ?.filter((o) => o.level === currentLevel)
      .map((category) => ({
        ...category,
        ...(isEmpty(childCategoriesList[category.id])
          ? {}
          : {
              children: childCategoriesList[category.id],
            }),
      })) || [];

  return formattedParentLevel;
};

export const findRootCategoryByLeaf = (categories: Dictionary<Category[]>, categoryleafId: number) => {
  const listCategory = values(categories);
  const level1 = listCategory.find((list) => {
    const level2 = list.find((subList) => {
      if (subList.isLeaf) {
        return subList.id === categoryleafId;
      }
      const level3 = subList.children?.find((item) => item.id === categoryleafId);
      return level3?.parentId;
    });
    return level2?.parentId;
  });
  return level1?.[0].parentId || '';
};

export const findCategoryInfoById = (categories: Dictionary<Category[]>, categoryId: number) => {
  const listCategory = values(categories);
  let category;
  for (const list of listCategory) {
    for (const subList of list) {
      if (subList.isLeaf && subList.id === categoryId) {
        category = subList;
      } else if (!subList.isLeaf) {
        category = subList.children?.find((item) => item.id === categoryId);
      }
      if (category) break;
    }
    if (category) break;
  }
  return category;
};

export const getUniqueAttributes: any = (attributesData: any) => {
  const uniqueAttributes = [...(attributesData ? attributesData : [])];
  if (
    uniqueAttributes?.length === 2 &&
    uniqueAttributes[0]?.selectedAttribute?.label?.toLowerCase() ===
      uniqueAttributes[1]?.selectedAttribute?.label?.toLowerCase()
  )
    uniqueAttributes.pop();

  return uniqueAttributes;
};

export const formatAttributeDataToCopy = (product: Product, isNoProductBranchingFeature: boolean | null) => {
  if (product?.variants?.length) {
    let initAttributeWithVariant = {};
    if (isNoProductBranchingFeature) {
      initAttributeWithVariant = {
        productStock: product?.productStock,
        price: product?.variants?.[0].price,
        costPrice: product?.variants?.[0].costPrice,
        maximumQuantityPerPost: product?.variants?.[0].maximumQuantityPerPost,
      };
    } else {
      const formattedAttribute =
        product?.attributes?.map((attribute) => ({
          selectedAttribute: {
            label: attribute.attributeName,
            value: attribute.productCategoryAttributeId,
          },
          id: attribute.id || '',
          options: attribute.options,
        })) || [];
      initAttributeWithVariant = {
        attributes: formattedAttribute,
      };
    }
    return { ...initAttributeWithVariant };
  } else {
    let initAttributeWithoutVariant = {};
    if (isNoProductBranchingFeature) {
      initAttributeWithoutVariant = {
        productStock: product?.productStock,
        price: product?.price,
        costPrice: product?.costPrice,
        maximumQuantityPerPost: product?.maximumQuantityPerPost,
      };
    }
    return { ...initAttributeWithoutVariant };
  }
};

export const formatAttributeDataToEdit: (product?: Product) => any = (product?: Product) => {
  if (product?.variants?.length) {
    let initAttributeWithVariant = {};
    const formattedAttribute =
      product?.attributes?.map((attribute) => ({
        selectedAttribute: {
          label: attribute.attributeName,
          value: attribute.productCategoryAttributeId,
        },
        id: attribute.id || '',
        options: attribute.options,
      })) || [];
    initAttributeWithVariant = {
      attributes: formattedAttribute,
    };

    return initAttributeWithVariant;
  } else {
    return {
      price: product?.price,
      costPrice: product?.costPrice,
      maximumQuantityPerPost: product?.maximumQuantityPerPost,
    };
  }
};

export const formatSingleVariantAsNormalProduct = (
  products: Product[],
  variantList: {
    [productId: string]: ProductVariant[];
  }
) => {
  const normalProducts = products.filter((product) => !product.masterProductId && !product.isMaster);
  const productsWithSingleVariant = flatten(Object.values(variantList).filter((o) => o?.length === 1));
  const formattedProductsWithSingleVariant = productsWithSingleVariant.map((variant) => ({
    ...variant,
    productImages: [variant.productImage],
  }));
  const masterProductsWithMoreVariants: Product[] = products.filter(
    (product) => product.isMaster && variantList[product?.id || '']?.length > 1
  );

  return [
    ...normalProducts,
    ...(formattedProductsWithSingleVariant as ProductVariant[]),
    ...masterProductsWithMoreVariants,
  ];
};

export const formatVariantsWithoutSingle = (variantList: { [productId: string]: ProductVariant[] }) => {
  return (
    Object.keys(variantList)
      .filter((key) => variantList[key]?.length > 1)
      .reduce((acc, masterId) => {
        return {
          ...acc,
          [masterId]: variantList[masterId],
        };
      }, {}) || {}
  );
};

export const generateVariantFromAttribute = (
  attributesData: {
    id: any;
    selectedAttribute: any;
    options: any;
  }[]
) => {
  let variants: VariantAttributeType[][] = [];

  if (attributesData.length) {
    attributesData?.map((attribute, index) => {
      const attributeId = attribute?.id || '';
      const attributeName = attribute?.selectedAttribute?.label || '';
      const defautVariant = {
        optionName: '',
        attributeName,
      };
      if (index === 0) {
        if (typeof attribute?.options === 'undefined' || attribute?.options.length === 0) {
          variants = [[defautVariant]];
        } else {
          variants = attribute?.options?.map(({ optionId, optionName }: OptionDataType) => [
            {
              optionName,
              ...(optionId ? { optionId } : {}),
              attributeId,
              attributeName,
            },
          ]);
        }
      } else {
        const newVariants = variants?.map((variant) => {
          if (typeof attribute?.options === 'undefined' || attribute?.options.length === 0) {
            return [[...variant, defautVariant]];
          } else {
            return attribute?.options?.map(({ optionId, optionName }: OptionDataType) => {
              return [
                ...variant,
                {
                  optionName,
                  ...(optionId ? { optionId } : {}),
                  attributeId,
                  attributeName,
                },
              ];
            });
          }
        });
        variants = flatten(newVariants);
      }
    });
  }
  return variants;
};

export const formatCustomerId = (customer?: AssociatedAccount) => {
  if (customer?.type && ['buyerApp', CustomerType.BuyerApp].includes(customer.type)) {
    if (customer?.id?.includes('facebook')) return customer.id.replace('facebook', 'buyerApp');
    return `buyerApp|${customer?.id || ''}`;
  }
  if (!customer?.id?.includes('facebook') && !customer?.id?.includes('instagram'))
    return `${convertCustomerType(customer?.type)}|${customer?.id}`;
  return customer?.id || '';
};

export const formatCustomerIds = (customer: AssociatedAccount[]) => {
  const uniqCustomers = uniqBy(customer, 'id');
  const formattedCustomer = uniqCustomers.map((customer) => formatCustomerId(customer));

  return formattedCustomer;
};

export const formatShippingNotificationType = (messageType?: MessageType) => {
  switch (messageType) {
    case MessageType.CartRejected:
      return 'shipping_notification.cart_rejected';
    case MessageType.CartPendingForVerification:
      return 'shipping_notification.cart_pending_for_verification';
    case MessageType.OrderPaid:
      return 'shipping_notification.order_created';
    case MessageType.OrderShipping:
      return 'shipping_notification.order_shipping';
    case MessageType.OrderDelivered:
      return 'shipping_notification.order_delivered';
    case MessageType.OrderDeliveryFailed:
      return 'shipping_notification.order_delivery_failed';
    case MessageType.All:
      return 'shipping_notification.all';
  }
};

export const formatProductsToMasterVariantFormat = (products: Product[]) => {
  return (
    products.map((product) => {
      if (product?.variants?.length) {
        return product;
      }
      return {
        attributes: [],
        categoryId: product.categoryId,
        description: product.description,
        height: product.height,
        id: product.id,
        length: product.length,
        productImages: product.productImages,
        productName: product.productName,
        productStock: product.productStock,
        sku: product.sku,
        variants: [
          {
            costPrice: product.costPrice,
            id: product.id,
            maximumQuantityPerPost: product.maximumQuantityPerPost,
            price: product.price,
            productCode: product.productCode,
            sku: product.sku,
            stock: product.productStock,
          },
        ],
        weight: product.weight,
        width: product.width,
      };
    }) || []
  );
};

export const convertESCartOrderData = (
  type: string,
  rawData: ESData[],
  data?: Dictionary<Cart | Order>
): Cart[] | Order[] => {
  switch (type) {
    case 'cart':
      return (
        rawData?.map((item) => {
          const cart = (data?.[item.cart_id || ''] as Cart) || {};
          const result = {
            cartStatus: item.cart_status,
            id: item.cart_id,
            pageName: item.page_names?.[0],
            created: item.cart_created_at,
            lastUpdated: item.cart_updated_at,
            orderCreated: item.order_created_at,
            total: item.cart_total,
            expiredAt: item.cart_expired_at,
            products: item.product_ids?.map((id) => ({ id: id })) || [],
            pageId: item.page_ids?.[0],
            cartCustomer: {
              name: item.customer_name || '',
              id: !item.customer_id?.includes('|') ? item.customer_id : '',
              quickViewCustomerType: item.customer_type || '',
            },
            offlinePaymentMethodName: item.offline_payment_method_name,
            paymentMethod: item.payment_method,
            tags: item.cart_tag_enums?.map(
              (o) =>
                ({
                  tagType: o,
                } as Tag)
            ),
            lastDownloadedAt: item.cart_last_downloaded_at,
            enableCartExpiry: item.cart_enable_cart_expiry,
            note: item.cart_note,
            hasPaymentProof: item.cart_has_payment_proof,
            updateReason: item.cart_update_reason,
            recipient: item.recipient_name,
            totalProductQuantity: item.total_product_quantity,
            paidAmount: item.paid_amount,
          };
          let buyerApp: AssociateAccount[] = [],
            recipient: AssociateAccount[] = [],
            commenter: AssociateAccount[] = [];
          if (!isEmpty(item.buyers)) {
            buyerApp =
              item?.buyers?.map((buyers) => ({
                id: buyers.id,
                name: buyers.name,
                type: 'buyerApp',
              })) || [];
          }
          if (item.recipient_name) {
            recipient = [
              {
                id: item.recipient_id || '',
                name: item.recipient_name || '',
                type: 'recipientName',
              },
            ];
          }
          if (item.customer_id && !item.buyer_ids?.includes(item.customer_id)) {
            commenter = [
              {
                id: item.customer_id || '',
                name: capitalize(item.customer_name || ''),
                type: item.customer_type || 'buyerApp',
              },
            ];
          }
          const associatedAccounts = [...commenter, ...buyerApp, ...recipient];
          return { ...result, ...cart, associatedAccounts };
        }) || []
      );
    case 'order':
      return (
        rawData?.map((item) => {
          const order = (data?.[item.order_id || ''] as Order) || {};
          const result = {
            id: item.order_id,
            cartId: item.cart_id,
            orderReferenceCode: item.order_reference_code,
            generalInformation: {
              customerName: capitalize(item.customer_name),
            },
            deliveryDetails: {
              recipient: capitalize(item.recipient_name),
              addressIdRef: item.order_address_id_ref,
            },
            paymentDetails: {
              total: item.cart_total,
            },
            cartCreated: item.cart_created_at,
            created: item.order_created_at,
            shipments: item.children?.map((o) => generateShipmentFromEsData(o)),
            pageName: item.page_names?.[0],
            pageId: item.page_ids?.[0],
            cartCustomer: {
              name: item.customer_name || '',
              id: !item.customer_id?.includes('|') ? item.customer_id : '',
              quickViewCustomerType: item.customer_type || '',
            },
            numberOfShipments: item.number_of_shipments,
            offlinePaymentMethodName: item.offline_payment_method_name,
            paymentMethod: item.payment_method,
            // VN
            courierDeliveryFee: item.order_total_courier_delivery_fee,
            trackingId: item.order_provider_tracking_id,
            codAmount: item.order_cod_amount,
            paymentStatus: item.order_payment_status,
            prePaid: item.order_prepaid,
            note: item.children?.[0]?.shipment_note,
            providerName: item.order_provider_courier_id,
            isMaster: item.is_master,
            fullShipmentIds: item.full_shipment_ids,
            ...(item.children_order ? { childrenOrders: convertESCartOrderData('order', item.children_order) } : {}),
            internalNote: item.internal_note,
            tags: item.cart_tag_enums?.map(
              (o) =>
                ({
                  tagType: o,
                } as Tag)
            ),
          };
          let buyerApp: AssociateAccount[] = [],
            commenter: AssociateAccount[] = [];
          if (!isEmpty(item.buyers)) {
            buyerApp =
              item?.buyers?.map((buyer) => ({
                id: buyer.id,
                name: buyer.name,
                type: 'buyerApp',
              })) || [];
          }
          if (item.customer_id && !item.buyer_ids?.includes(item.customer_id)) {
            commenter = [
              {
                id: item.customer_id || '',
                name: capitalize(item.customer_name || ''),
                type: item.customer_type || 'buyerApp',
              },
            ];
          }
          const associatedAccounts = [...commenter, ...buyerApp];
          return { ...result, ...order, associatedAccounts };
        }) || []
      );
    default:
      return [];
  }
};

export const generateReportTitle = (type: ReportTemplate) => {
  switch (type) {
    case ReportTemplate.Fulfillment:
      return 'fulfilment report';
    case ReportTemplate.PackingList:
      return 'packing list report';
    case ReportTemplate.QuantitySold:
      return 'quantity sold report';
    case ReportTemplate.TopSpender:
      return 'top spender report';
    case ReportTemplate.TransactionSale:
      return 'transaction sale report';
    case ReportTemplate.FillupPurchaseOrder:
      return 'Purchase Order';
  }
};

export const handleGetAddressByPostalCode = async (postalCode: string) => {
  const genURL = `https://www.onemap.gov.sg/api/common/elastic/search?searchVal=${postalCode}&returnGeom=Y&getAddrDetails=Y`;
  const response = await fetch(genURL);
  const data = await response.json();
  const address = data?.results?.[0]?.ADDRESS || '';
  return address;
};

export const checkDownloadedBeforeUpdated = (download?: string, update?: string) => {
  if (!download || !update) return false;
  return compareAsc(new Date(update), new Date(download)) === 1;
};

export const sortArrayWithKeyDirection = (array: any, sortKey: string, direction: string) => {
  // Copy the array to avoid modifying the original array
  const sortedArray = [...array];

  sortedArray.sort((a, b) => {
    // Extract the sort values from the objects based on the sort key
    const sortValueA = a[sortKey];
    const sortValueB = b[sortKey];

    let comparison = 0;

    if (sortValueA > sortValueB) {
      comparison = 1;
    } else if (sortValueA < sortValueB) {
      comparison = -1;
    }

    // Reverse the comparison result if the direction is descending
    if (direction === 'desc') {
      comparison *= -1;
    }

    return comparison;
  });

  return sortedArray;
};

export const calcOffset = (pageNumber: number, totalCount: number[]) => {
  let offset = 0;
  for (let i = 0; i <= pageNumber - 1; i++) {
    offset += totalCount[i];
  }
  return offset;
};

export const replaceTextWithUrls = (
  inputString: string,
  urls: string[],
  config?: {
    classname?: string;
    target?: string;
  }
) => {
  const pattern = /\[([^\]]+)\]/g;
  let index = 0;

  const replace = (match: string, textInsideBrackets: string) => {
    const url = urls[index];
    index++;

    return url
      ? `<a class="${config?.classname || 'text-blue-500'}" href="${url}" target="${
          config?.target || '_blank'
        }" rel="noreferrer">${textInsideBrackets}</a>`
      : match;
  };

  const replacedString = inputString.replace(pattern, replace);

  return replacedString;
};

const getAbbreviation = (word: string, number: number) => {
  const pattern = isTH ? /[^\w\d\s\u0E00-\u0E7F]/g : /[^a-zA-Z0-9 ]/g;
  return word.replace(pattern, '').slice(0, number).toUpperCase();
};

export const combineVariationToProductCode = (variants: VariantAttributeType[], firstNumber = 2, secondNumber = 2) => {
  const variationValue = variants.map((item) => item.optionName) || [];

  return variationValue
    .map((variant, i) => {
      const words = variant.split(' ');
      const number = i === 0 ? firstNumber : secondNumber;

      return words.map((word, index) => (index < 2 ? getAbbreviation(word, number) : '')).join('');
    })
    .join('');
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const countProprety = (obj: any, keywords: string) => {
  let count = 0;
  for (const key in obj) {
    if (key.includes(keywords)) {
      count++;
    }
  }
  return count;
};

export const generateRequiredMsgOption = (type: MessageType): string[] => {
  switch (type) {
    case MessageType.DefaultCheckoutMessage:
      return [
        '[SELLING_CODE]',
        '[PRODUCT_NAME]',
        '[VARIATION]',
        '[PRODUCT_QUANTITY]',
        '[SELLING_PRICE]',
        '[CHECKOUT_LINK]',
      ];
    case MessageType.ProductDeactivation:
    case MessageType.OutOfStock:
      return ['[SELLING_CODE]', '[PRODUCT_NAME]', '[VARIATION]'];
    case MessageType.PaymentReminder:
      return ['[CART_QUANTITY]', '[SUBTOTAL]', '[CHECKOUT_LINK]'];
    case MessageType.CartPendingForVerification:
    case MessageType.CartRejected:
    case MessageType.OrderPaid:
    case MessageType.OrderShipping:
    case MessageType.OrderDelivered:
    case MessageType.OrderDeliveryFailed:
      return ['[CHECKOUT_LINK]'];
    default:
      return [];
  }
};

export const generateOptionaldMsgOption = (type: MessageType): string[] => {
  switch (type) {
    case MessageType.DefaultCheckoutMessage:
      return ['[CART_SUMMARY]', '[SUBTOTAL]', '[SHIPPING_GROUP_NOTES]'];
    case MessageType.CartPendingForVerification:
    case MessageType.CartRejected:
      return ['[TOTAL]', '[CART_ID]'];
    case MessageType.OrderPaid:
    case MessageType.OrderShipping:
    case MessageType.OrderDelivered:
    case MessageType.OrderDeliveryFailed:
      return ['[TOTAL]', '[CART_ID]', '[SHIPMENT_ID]'];
    case MessageType.PaymentReminder:
      return ['[CART_ID]'];
    default:
      return [];
  }
};

export const generateTextMsgOption = (text: string) => {
  switch (text) {
    case '[SELLING_CODE]':
      return 'selling_code';
    case '[PRODUCT_NAME]':
      return 'product_name';
    case '[VARIATION]':
      return 'variation';
    case '[PRODUCT_QUANTITY]':
      return 'product_quantity';
    case '[SELLING_PRICE]':
      return 'price';
    case '[SUBTOTAL]':
      return 'cart_amount';
    case '[CART_ID]':
      return 'cart_id';
    case '[SHIPPING_GROUP_NOTES]':
      return 'shipping_group_notes';
    case '[CHECKOUT_LINK]':
      return 'checkout_link';
    case '[TOTAL]':
      return 'checkout_amount';
    case '[SHIPMENT_ID]':
      return 'shipment_id';
    case '[CART_QUANTITY]':
      return 'cart_quantity';
    case '[BUYER_NAME]':
      return 'buyer_name';
    case '[CART_SUMMARY]':
      return 'cart_summary';
    default:
      return text;
  }
};

export const generateRequiredReplyOption = (type: ReplyCommentType): string[] => {
  switch (type) {
    case ReplyCommentType.AllComment:
      return ['[BUYER_NAME]'];
    case ReplyCommentType.ValidSellingCode:
      return ['[SELLING_CODE]', '[BUYER_NAME]'];
    case ReplyCommentType.CongratsFirstBidder:
      return ['[BUYER_NAME]', '[PRODUCT_NAME]'];
    case ReplyCommentType.SoldOut:
      return ['[PRODUCT_NAME]'];
    default:
      return [];
  }
};

export const generateOptionaldReplyOption = (type: ReplyCommentType): string[] => {
  switch (type) {
    case ReplyCommentType.ValidSellingCode:
      return ['[PRODUCT_NAME]', '[VARIATION]'];
    case ReplyCommentType.CongratsFirstBidder:
      return ['[SELLING_CODE]', '[PRODUCT_QUANTITY]', '[VARIATION]'];
    case ReplyCommentType.SoldOut:
      return ['[SELLING_CODE]', '[VARIATION]'];
    default:
      return [];
  }
};

// for CURRENCY
export const getPrecisionNumber = () => {
  if (isVN) {
    return 0;
  }

  return 2;
};

export const generateCommonTrackingData = () => {
  const url = new URL(window.location.href);
  const pageKeyName = url.pathname.split('/').filter((o) => !!o)?.[0] || '';
  const eventTrackingDataRef = {
    page_screen: pages[`/${pageKeyName}`],
    page_link: url.href,
  };

  return eventTrackingDataRef;
};
