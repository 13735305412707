import Papa from 'papaparse';
import ExcelJS from 'exceljs';

import { AppRegion } from 'hooks/app';

export function convertLetterToNumber(str: string) {
  let column = 0;
  const length = str.length;
  for (let i = 0; i < length; i++) {
    column += (str.charCodeAt(i) - 64) * Math.pow(26, length - i - 1);
  }
  return column;
}

export function convertNumberToLetter(column: number) {
  let temp;
  let letter = '';
  while (column > 0) {
    temp = (column - 1) % 26;
    letter = String.fromCharCode(temp + 65) + letter;
    column = (column - temp - 1) / 26;
  }
  return letter;
}

// range example `A1:EG409`
export function getRange(range: string) {
  const regex = /[A-Z]+[0-9]+:([A-Z]+)([0-9]+)/gm;
  const result = regex.exec(range);

  return {
    columns: convertLetterToNumber(result?.[1] || 'A'),
    rows: result?.[2] ? Number.parseInt(result?.[2]) : 0,
  };
}

export async function loadCSV(data: File): Promise<string[][]> {
  return new Promise<string[][]>((resolve) => {
    Papa.parse(data, {
      worker: true,

      complete: function (results) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        resolve(results.data as any);
      },
    });
  });
}

export function generateOrderMapping(
  data: string[][],
  i: number,
  buyer: string,
  identifier: string,
  cartID: string,
  reasonColumnIndex: number,
  region: AppRegion
) {
  if (region === AppRegion.VN) {
    return {
      'Buyer FB': buyer,
      'Recipient Name': data[i][5],
      Identifier: identifier,
      'Cart ID': cartID,
      'Ref#': data[i][3],
      Email: data[i][4],
      'Delivery Name': data[i][5],
      'Buyer Address': data[i][6],
      'Contact No': data[i][7],
      'Purchase Value': data[i][8],
      'Delivery Fee': parseFloat(data[i][9] || '0'),
      'Delivery Instruction': data[i][10],
      'Delivery Method': data[i][11],
      'Promo Code': data[i][12],
      'Promo Deduction': parseFloat(data[i][13]),
      'Bundle Deal': data[i][14],
      'Bundle Discount': parseFloat(data[i][15]),
      'Checkout Amount': parseFloat(data[i][16]),
      'Cart Status': data[i][20],
      'Payment Method': data[i][21],
      'Sort Date': data[i][22],
      'Sort Time': data[i][23],
      'Creation Time': data[i][24],
      'Checkout Time': data[i][25],
      'Delivery Status': data[i][26],
      Reason: data[i][reasonColumnIndex],
      'Order Type': data[i][reasonColumnIndex + 1],
      products: [],
    };
  }
  return {
    'Buyer FB': buyer,
    'Recipient Name': data[i][5],
    Identifier: identifier,
    'Cart ID': cartID,
    'Ref#': data[i][3],
    Email: data[i][4],
    'Delivery Name': data[i][5],
    'Buyer Address': data[i][6],
    'Postal Code': data[i][7],
    'Contact No': data[i][8],
    'Purchase Value': data[i][9],
    'Delivery Fee': parseFloat(data[i][10] || '0'),
    'Delivery Instruction': data[i][11],
    'Delivery Method': data[i][12],
    'Promo Code': data[i][13],
    'Promo Deduction': parseFloat(data[i][14]),
    'Bundle Deal': data[i][15],
    'Bundle Discount': parseFloat(data[i][16]),
    'Member Discount': parseFloat(data[i][17]),
    'Seller Adjustment': parseFloat(data[i][18]),
    'Buyer Credit': parseFloat(data[i][19]),
    'Buyer txn fee': parseFloat(data[i][20]),
    'Checkout Amount': parseFloat(data[i][21]),
    'Paid Amount': parseFloat(data[i][22] || '0'),
    Outstanding: parseFloat(data[i][23] || '0'),
    'Cart Status': data[i][24],
    '2C2P Order Id': data[i][25],
    'Payment Method': data[i][26],
    'Sort Date': data[i][27],
    'Sort Time': data[i][28],
    'Creation Time': data[i][29],
    'Checkout Time': data[i][20],
    'Delivery Status': data[i][31],
    Reason: data[i][reasonColumnIndex],
    'Order Type': data[i][reasonColumnIndex + 1],
    products: [],
  };
}

export function generateCartMapping(
  data: string[][],
  i: number,
  buyer: string,
  identifier: string,
  cartID: string,
  reasonColumnIndex: number,
  region: AppRegion
) {
  if (region === AppRegion.VN) {
    return {
      'Buyer FB': buyer,
      'Recipient Name': data[i][5],
      Identifier: identifier,
      'Cart ID': cartID,
      'Ref#': data[i][3],
      Email: data[i][4],
      'Delivery Name': data[i][5],
      'Buyer Address': data[i][6],
      'Contact No': data[i][7],
      'Purchase Value': data[i][8],
      'Delivery Fee': parseFloat(data[i][9] || '0'),
      'Delivery Instruction': data[i][10],
      'Delivery Method': data[i][11],
      'Promo Code': data[i][12],
      'Promo Deduction': parseFloat(data[i][13]),
      'Bundle Deal': data[i][14],
      'Bundle Discount': parseFloat(data[i][15]),
      'Checkout Amount': parseFloat(data[i][16]),
      'Cart Status': data[i][20],
      'Payment Method': data[i][21],
      'Sort Date': data[i][22],
      'Sort Time': data[i][23],
      'Creation Time': data[i][24],
      'Checkout Time': data[i][25],
      'Delivery Status': data[i][26],
      Reason: data[i][reasonColumnIndex],
      products: [],
    };
  }
  return {
    'Buyer FB': buyer,
    'Recipient Name': data[i][5],
    Identifier: identifier,
    'Cart ID': cartID,
    'Ref#': data[i][3],
    Email: data[i][4],
    'Delivery Name': data[i][5],
    'Buyer Address': data[i][6],
    'Postal Code': data[i][7],
    'Contact No': data[i][8],
    'Purchase Value': data[i][9],
    'Delivery Fee': parseFloat(data[i][10] || '0'),
    'Delivery Instruction': data[i][11],
    'Delivery Method': data[i][12],
    'Promo Code': data[i][13],
    'Promo Deduction': parseFloat(data[i][14]),
    'Bundle Deal': data[i][15],
    'Bundle Discount': parseFloat(data[i][16]),
    'Member Discount': parseFloat(data[i][17]),
    'Seller Adjustment': parseFloat(data[i][18]),
    'Buyer Credit': parseFloat(data[i][19]),
    'Buyer txn fee': parseFloat(data[i][20]),
    'Checkout Amount': parseFloat(data[i][21]),
    'Paid Amount': parseFloat(data[i][22] || '0'),
    Outstanding: parseFloat(data[i][23] || '0'),
    'Cart Status': data[i][24],
    '2C2P Order Id': data[i][25],
    'Payment Method': data[i][26],
    'Sort Date': data[i][27],
    'Sort Time': data[i][28],
    'Creation Time': data[i][29],
    'Checkout Time': data[i][30],
    'Delivery Status': data[i][31],
    Reason: data[i][reasonColumnIndex],
    products: [],
  };
}

export const exportCsvDataToExcel = async (data: string, sheetName: string, fileName: string) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet(sheetName);
  const file = new File([data], fileName);
  const csvData = await loadCSV(file);

  sheet.addRows(csvData);

  return workbook;
};
